<template lang="pug">
div
  om-dropdown-input(
    label="onClick"
    @change="actionTypeChanged($event.target.value)"
    property="data.actionType"
    :items="surveyActionTypes"
  )
  om-switches(label="dontCountAsConversion" property="selectedElement.data.dontCountAsConversion")
  template(v-if="actionType === 'jumpToPage'")
    om-switches(
      label="samePageForAllOptions"
      @change.native="setJumpToAll()"
      property="selectedElement.data.samePageForAllOptions"
    )
    template(v-if="samePageForAllOptions")
      om-dropdown-input(
        label="jumpToPage"
        property="data.jumpToPage"
        @change="setJumpToAll($event)"
        :items="pageNames"
        :i18n="false"
      )
    template(v-else)
      div(v-for="(item, index) in options" :key="item.value")
        om-dropdown-input(
          :label="item.key"
          :property="optionProperty(index, 'jumpToPage')"
          @change.native="updateAllOptions()"
          :items="pageNames"
          :i18n="false"
        )
  template(v-else-if="actionType === 'redirect'")
    .d-flex.align-items-center
      .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0.pr-2 {{ $t('sameRedirectForAllOptions') }}
      om-switches(
        label=""
        @change.native="setRedirectToAll()"
        property="selectedElement.data.sameRedirectForAllOptions"
      )
    template(v-if="sameRedirectForAllOptions")
      .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{ $t('redirect') }}
      om-simple-input(
        label=""
        property=""
        @input.native="setRedirectToAll($event)"
        placeholder="https://www.example.com/"
        :validationErrorMsg="redirectUrlAllError"
      )
      om-switches(
        label="keepQueryParams"
        @change="setAllOptionsProperty('keepQueryParams', $event)"
        property="data.keepQueryParams"
        :enabledText="$t('yes')"
        :disabledText="$t('no')"
      )
      om-switches(
        label="openInNewTab"
        @change="setAllOptionsProperty('newTab', $event)"
        property="data.newTab"
        :enabledText="$t('yes')"
        :disabledText="$t('no')"
      )
    template(v-else)
      div(v-for="(item, index) in options" :key="item.value")
        .sidebar-input-wrapper.sidebar-input-wrapper-label.pb-0 {{ `${item.key.length > 20 ? `${item.key.substr(0, 20)}...` : item.key}` }}
        om-simple-input(
          label=""
          :property="optionProperty(index, 'redirectUrl')"
          @input.native="updateOption(index, 'redirectUrl', $event.target.value)"
          :i18n="false"
          placeholder="https://www.example.com/"
          :validationErrorMsg="redirectUrlOptionError(index)"
        )
        om-switches(
          label="keepQueryParams"
          :property="optionProperty(index, 'keepQueryParams')"
          :enabledText="$t('yes')"
          :disabledText="$t('no')"
        )
        om-switches(
          label="openInNewTab"
          :property="optionProperty(index, 'newTab')"
          :enabledText="$t('yes')"
          :disabledText="$t('no')"
        )
</template>

<script>
  import itemMixin from '@/editor/mixins/item';
  import { mapState, mapGetters } from 'vuex';
  import { debounce } from 'lodash-es';

  export default {
    mixins: [itemMixin],

    computed: {
      ...mapState(['selectedElement', 'pages', 'validationError']),
      ...mapGetters(['selectedPageIndex', 'hasAccountFeature', 'isEmbedded']),
      surveyActionTypes() {
        const actionTypes = [
          { key: 'nextPopup', value: 'nextPopup' },
          { key: 'customStep', value: 'jumpToPage' },
          { key: 'redirect', value: 'redirect' },
        ];
        if (this.isEmbedded) return actionTypes;

        return [...actionTypes, { key: 'closePopup', value: 'closePopup' }];
      },
      actionType() {
        return this.selectedElement.data.actionType;
      },
      samePageForAllOptions() {
        return this.selectedElement.data.samePageForAllOptions;
      },
      sameRedirectForAllOptions() {
        return this.selectedElement.data.sameRedirectForAllOptions;
      },
      pageNames() {
        const names = [];
        this.pages.forEach((p, index) => {
          if (index > this.selectedPageIndex) {
            names.push({ key: p.data.title, value: index + 1 });
          }
        });

        return [{ key: this.$t('none'), value: null }, ...names];
      },
      options() {
        return this.selectedElement.data.form.customSettings.options;
      },
      optionsLength() {
        return this.options.length;
      },
      redirectUrlAllError() {
        if (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('surveyRedirectUrlAll')
        ) {
          return this.validationError.msg;
        }
        return '';
      },
    },

    watch: {
      optionsLength() {
        this.actionTypeChanged(this.actionType);

        if (this.samePageForAllOptions && this.actionType === 'jumpToPage') {
          this.setAllOptionsProperty('jumpToPage', this.selectedElement.data.jumpToPage);
        }

        if (this.sameRedirectForAllOptions && this.actionType === 'redirect') {
          this.setAllOptionsProperty('keepQueryParams', this.selectedElement.data.keepQueryParams);
          this.setAllOptionsProperty('newTab', this.selectedElement.data.newTab);
          this.setAllOptionsProperty('redirectUrl', this.selectedElement.data.redirectUrl);
        }
      },
    },

    methods: {
      optionProperty(index, key) {
        return `data.form.customSettings.options.${index}.${key}`;
      },
      updateAllOptions() {
        this.$store.commit('updateData', {
          property: 'selectedElement.data.form.customSettings.options',
          value: this.selectedElement.data.form.customSettings.options,
        });
      },
      setAllOptionsProperty(property, value) {
        this.selectedElement.data.form.customSettings.options.forEach((o) => {
          o[property] = value;
        });
        this.updateAllOptions();
      },
      actionTypeChanged(value) {
        this.setAllOptionsProperty('action', value);
      },
      setJumpToAll(event) {
        let value;
        if (!event) {
          value = this.selectedElement.data.jumpToPage;
        } else {
          value = event.target.value;
        }
        this.setAllOptionsProperty('jumpToPage', value);
      },
      setRedirectToAll: debounce(function (event) {
        let value;
        if (!event) {
          value = this.selectedElement.data.redirectUrl;
          this.setAllOptionsProperty('keepQueryParams', this.selectedElement.data.keepQueryParams);
          this.setAllOptionsProperty('newTab', this.selectedElement.data.newTab);
        } else {
          value = event.target.value;
        }
        this.setAllOptionsProperty('redirectUrl', value);
        this.$set(this.selectedElement.data, 'redirectUrl', value);
      }, 600),
      updateOption: debounce(function (index, property, value) {
        this.$set(this.selectedElement.data.form.customSettings.options[index], property, value);
      }, 600),
      redirectUrlOptionError(index) {
        if (
          this.validationError &&
          this.validationError.property &&
          this.validationError.property.includes('surveyRedirectUrlOption') &&
          this.validationError.errors.some((e) => e.index === index)
        ) {
          return this.validationError.msg;
        }
        return '';
      },
    },
  };
</script>
